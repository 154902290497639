import { Box, Heading } from "@chakra-ui/core"
import { graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import { Container } from "../components/container"
import { Title } from "../components/title"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

const Impress: FunctionComponent<{ data: any }> = ({ data }) => {
  const impress = data.prismicImpress.data

  return (
    <Layout>
      <Container>
        <SEO title={impress.title?.text} />
        <Title text={impress.title?.text} />
        <Box
          w="100%"
          dangerouslySetInnerHTML={{ __html: impress.content.html }}
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Impress {
    prismicImpress {
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
  }
`

export default Impress
